/* eslint-disable no-process-env */

import {
  looseHrefSchema,
  NextPublicVercelEnvSchema,
} from '@purposity/utils/edge'
import * as z from 'zod'
import { PartialEnv } from './_utils'

/**
 * Specify server-side environment variables schema here to ensure app isn't built with invalid env vars.
 */

export const ServerSchema = z.object({
  NODE_ENV: z.enum(['development', 'test', 'production']),
  ADMIN_API_SECRET: z.string(),
  ADMIN_SITE_ORIGIN: looseHrefSchema,
  API_ORIGIN: looseHrefSchema,
  CLERK_API_KEY: z.string(),
  CLERK_JWT_KEY: z.string(),
  DOPPLER_CONFIG: z.string(),
  DOPPLER_ENVIRONMENT: z.enum(['dev', 'preview', 'prod']),
  DOPPLER_PROJECT: z.string(),
  FIREBASE_CLIENT_EMAIL: z.string(),
  FIREBASE_PRIVATE_KEY: z.string(),
  LEGACY_JWT_KEY: z.string(),
  SECRET_KEY: z.string(),
  SENTRY_AUTH_TOKEN: z.string(),
  SENTRY_DSN: z.string().optional(),
  SENTRY_ORG: z.string(),
  SENTRY_PROJECT: z.string(),
  STRIPE_SECRET_KEY: z.string(),
  UPSTASH_REDIS_REST_TOKEN: z.string(),
  UPSTASH_REDIS_REST_URL: looseHrefSchema,
})
type ServerSchema = z.infer<typeof ClientSchema>

/**
 * Specify client-side environment variables here to ensure app isn't built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const ClientSchema = NextPublicVercelEnvSchema.partial().extend({
  NEXT_PUBLIC_SENTRY_DSN: z.string(),
})
type ClientSchema = z.infer<typeof ClientSchema>

/**
 * You can't destruct `process.env` as a regular object, so you have to do it manually here. This is because Next.js evaluates this at build time, and only used environment variables are included in the build.
 */
export const clientEnv: PartialEnv<ClientSchema> = {
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_GIT_PROVIDER: process.env.NEXT_PUBLIC_VERCEL_GIT_PROVIDER,
  NEXT_PUBLIC_VERCEL_GIT_REPO_ID: process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_ID,
  NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER:
    process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER,
  NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG:
    process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
}
