import * as z from 'zod'

export const Environment = z.enum([
  'development',
  'preview',
  'production',
  'test',
  'local',
])
export type Environment = z.infer<typeof Environment>

export const PURPOSITY_ENV: Environment =
  process.env.VERCEL_ENV ||
  process.env.NEXT_PUBLIC_VERCEL_ENV ||
  (process.env.NODE_ENV === 'production' ? 'local' : process.env.NODE_ENV) ||
  'development'

export const WEBHOOK_SECRET_HEADER_KEY = 'x-hasura-webhook-secret'
