import * as z from 'zod'

export const formatErrors = (
  errors: z.ZodFormattedError<Map<string, string>, string>
) =>
  Object.entries(errors)
    .map(([name, value]) => {
      if (value && '_errors' in value)
        return `${name}: ${value._errors.join(', ')}\n`
    })
    .filter(Boolean)

export function logInvalidEnvVars(error: z.ZodError) {
  const message = 'Invalid environment variables'
  console.error(`❌ ${message}:\n`, ...formatErrors(error.format()))
  return message
}

export type PartialEnv<T extends z.infer<z.ZodObject<any>>> = {
  [k in keyof T]: T[k] | undefined
}
