import { logInvalidEnvVars } from './_utils'
import { clientEnv, ClientSchema } from './schema'

const _clientEnv = ClientSchema.safeParse(clientEnv)

if (_clientEnv.success === false) {
  const message = logInvalidEnvVars(_clientEnv.error)
  throw new Error(message)
}

/** Validate client-side environment variables are exposed to the client */
for (const key of Object.keys(_clientEnv.data)) {
  if (!key.startsWith('NEXT_PUBLIC_')) {
    console.warn('❌ Invalid public environment variable name:', key)
    throw new Error('Invalid public environment variable name')
  }
}

export const env = _clientEnv.data
