import * as Sentry from '@sentry/nextjs'
import * as z from 'zod'
import { env } from './src/env/client'
import { PURPOSITY_ENV } from '~/constants'

Sentry.init({
  dsn:
    'https://0a571a9bb71a4200931ed42e7a04923a@o546790.ingest.sentry.io/4504234316988416' ||
    env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: PURPOSITY_ENV,
})

Sentry.addGlobalEventProcessor(function (event, hint) {
  /** Format ZodErrors for Sentry */
  if (hint.originalException instanceof z.ZodError) {
    return {
      ...event,
      extra: {
        ...event.extra,
        'validation-issues': hint.originalException.format(),
      },
    }
  }

  return event
})
